import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-2/pic1.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-2/pic2.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-2/pic3.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-2/pic4.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-2/pic5.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-2/pic6.JPG",
];

export default function FirstAidAndBLSWorkshop() {
  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">FIRST AID AND BLS WORKSHOP</h1>

        <p>
          In order to enhance awareness amongst the staff members with regard to
          the first aid measures to be taken in critical situations, Chinmaya
          Vidyalaya, New Delhi,conducted an informative workshop on 30th June
          2023. The staff was shown a demonstration on the appropriate ways by
          which an accidental electric shock can be handled. It was followed by
          a short demonstration on the remedial measures for the problem of
          choking among children.A step by step and detailed demonstration on
          administering CPR and artificial breathing was also shared.
        </p>
        <section className="kartavyapath__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
